var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group",
    { attrs: { flush: "" } },
    [
      _vm.displayAdsense
        ? _c(
            "b-list-group-item",
            { staticClass: "adsense" },
            [
              _c("adsense-ad", {
                attrs: { "ad-name": "showTop", "ad-format": "auto" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function(item, index) {
        return _c("item", {
          key: item.name,
          attrs: { item: item, index: index }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }