var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-alert", {
    attrs: { variant: "success", show: _vm.show },
    domProps: { innerHTML: _vm._s(_vm.$tm("actions.download")) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }