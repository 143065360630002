var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.user
      ? _c("a", { attrs: { href: _vm.authURL } }, [
          _vm._v(_vm._s(_vm.$tm("common.sponsor login")))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.user
      ? _c("span", { staticClass: "greeting", attrs: { href: _vm.authURL } }, [
          _vm._v(
            _vm._s(_vm.$tm("common.greeting")) +
              ", " +
              _vm._s(_vm.user.firstName || "Anonumous") +
              "! 🎉🎉🎉"
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.user
      ? _c("a", { attrs: { href: _vm.authURL } }, [
          _vm._v(_vm._s(_vm.$tm("common.not you")))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }