var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-input", {
    attrs: {
      size: _vm.size,
      placeholder: _vm.$t("common.paste magnet url"),
      state: _vm.magnetUrlState
    },
    on: { change: _vm.onMagnetChange, paste: _vm.onMagnetPaste },
    model: {
      value: _vm.magnetUrl,
      callback: function($$v) {
        _vm.magnetUrl = $$v
      },
      expression: "magnetUrl"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }