var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { staticClass: "tool-layout" },
    [
      _c("h1", [
        _c("div", { staticClass: "main-header" }, [_vm._t("header")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "sub-header" }, [_vm._t("lead")], 2)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tool-wrapper" }, [
        _c("div", { staticClass: "tool-content" }, [_vm._t("default")], 2)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "features" }, [_vm._t("features")], 2),
      _vm._v(" "),
      _c("adsense-ad", { attrs: { "ad-name": "tool", "ad-format": "auto" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }