var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("h1", [_vm._v("DMCA")]),
    _vm._v(" "),
    _c("div", { domProps: { innerHTML: _vm._s(_vm.dmca) } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }