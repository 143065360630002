import { render, staticRenderFns } from "./DownloadZip.vue?vue&type=template&id=183a21f6&scoped=true&"
import script from "./DownloadZip.vue?vue&type=script&lang=js&"
export * from "./DownloadZip.vue?vue&type=script&lang=js&"
import style0 from "./DownloadZip.vue?vue&type=style&index=0&id=183a21f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "183a21f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Home/vintikzzzz/Projects/webtor/webtor-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('183a21f6')) {
      api.createRecord('183a21f6', component.options)
    } else {
      api.reload('183a21f6', component.options)
    }
    module.hot.accept("./DownloadZip.vue?vue&type=template&id=183a21f6&scoped=true&", function () {
      api.rerender('183a21f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/src/components/App/Show/DownloadZip.vue"
export default component.exports