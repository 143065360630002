var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEmbedded
    ? _c(
        "div",
        { staticClass: "intro" },
        [
          _c("nav-bar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "pre",
                  fn: function() {
                    return [_c("div")]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2366857556
            )
          }),
          _vm._v(" "),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "justify-content-md-center" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("logo", { staticClass: "logo" })],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "12" } },
                    [
                      _c(
                        "b-jumbotron",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c("div", {
                                      staticClass: "main-header text-center",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$tm("intro.header")
                                        )
                                      }
                                    }),
                                    _c("div", {
                                      staticClass: "sub-header text-center",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$tm("intro.header2")
                                        )
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "lead",
                                fn: function() {
                                  return [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.$tm("intro.lead"))
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1387000256
                          )
                        },
                        [
                          _vm._v(" "),
                          _vm._v(" "),
                          _c("send"),
                          _vm._v(" "),
                          _c("demo", { staticClass: "demo" }),
                          _vm._v(" "),
                          _vm.hasLogin
                            ? _c("login", { staticClass: "login" })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "features" },
                [
                  _c(
                    "b-row",
                    { staticClass: "adsense justify-content-md-center" },
                    [
                      _vm.displayAdsense
                        ? _c(
                            "b-col",
                            { attrs: { lg: "10" } },
                            [
                              _c("adsense-ad", {
                                attrs: {
                                  "ad-name": "introTop",
                                  "ad-format": "auto"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [_c("feature", { attrs: { name: "extension" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [_c("feature", { attrs: { name: "subtitles" } })],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c("feature", {
                            attrs: { name: "instant streaming" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c("feature", {
                            attrs: { name: "on-the-fly transcoding" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [_c("feature", { attrs: { name: "download zip" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [_c("feature", { attrs: { name: "player" } })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "badges" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://www.producthunt.com/posts/webtor?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-webtor",
                      target: "_blank"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "250px", height: "54px" },
                      attrs: {
                        src:
                          "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=295353&theme=dark",
                        alt:
                          "Webtor - Download and play torrents with free cloud BitTorrent client | Product Hunt",
                        width: "250",
                        height: "54"
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "faq" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { lg: "12" } }, [
                        _c("h2", [_vm._v(_vm._s(_vm.$tm("intro.faq")))])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [_c("feature", { attrs: { name: "safe" } })],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [_c("feature", { attrs: { name: "donate" } })],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [_c("feature", { attrs: { name: "mobile" } })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.displayAdsense
                ? _c(
                    "div",
                    { staticClass: "adsense-bottom" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { lg: "12" } },
                            [
                              _c("adsense-ad", {
                                attrs: {
                                  "ad-name": "introBottom",
                                  "ad-format": "auto"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.displayAdsense && _vm.windowWidth > 1425
            ? _c("adsense-ad", {
                staticClass: "sticky-ad",
                attrs: { "ad-name": "introLeftSticky", "ad-format": "auto" }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }