var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alerts" },
    [
      _c("wrong-format"),
      _vm._v(" "),
      _c("abused"),
      _vm._v(" "),
      _c("delivery-failure"),
      _vm._v(" "),
      _c("load"),
      _vm._v(" "),
      _c("download"),
      _vm._v(" "),
      _c("ad-block"),
      _vm._v(" "),
      _c("api-unreachable"),
      _vm._v(" "),
      _c("zip")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }