var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default-layout" },
    [
      _c("nav-bar", { staticClass: "nav" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [_c("b-col", { attrs: { lg: "12" } }, [_vm._t("default")], 2)],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }