var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown"
        }
      ],
      class: _vm.groupClass
    },
    [
      _c(
        "label",
        {
          staticClass: "btn btn-primary btn-lg send-file",
          attrs: { role: "button" }
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.$tm("common.open torrent")) }
          }),
          _vm._v(" "),
          _c("input", {
            ref: "in",
            attrs: { type: "file", hidden: "" },
            on: { change: _vm.onChange }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary btn-lg dropdown-toggle dropdown-toggle-split",
          attrs: {
            type: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          },
          on: { click: _vm.toggleDropdown }
        },
        [_c("span", { staticClass: "sr-only" }, [_vm._v("Toggle Dropdown")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "dropdown", class: _vm.dropdownClass },
        [
          _c(
            "b-dropdown-form",
            {
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                }
              }
            },
            [
              !_vm.noMagnet
                ? _c(
                    "b-form-group",
                    [
                      _c("icon", {
                        staticClass: "magnet",
                        attrs: { name: "magnet" }
                      }),
                      _vm._v(" "),
                      _c("magnet", { on: { change: _vm.onMagnetChange } })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasRecentTorrents
            ? _c(
                "b-dropdown-group",
                { attrs: { header: _vm.$t("common.latest torrents") } },
                _vm._l(_vm.recentTorrents, function(item, index) {
                  return _c("item", {
                    key: index,
                    attrs: { item: item, index: index },
                    on: { select: _vm.onSelect }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasRecentTorrents
            ? _c("b-dropdown-header", [
                _vm._v(_vm._s(_vm.$t("common.no recent torrents")))
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }