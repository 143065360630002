var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-nav-item", { class: _vm.cl, attrs: { href: _vm.href } }, [
    _vm._v("️"),
    _c("span", { staticClass: "heart" }, [_vm._v(_vm._s(_vm.heart))]),
    _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }