import { render, staticRenderFns } from "./Alerts.vue?vue&type=template&id=119aa806&scoped=true&"
import script from "./Alerts.vue?vue&type=script&lang=js&"
export * from "./Alerts.vue?vue&type=script&lang=js&"
import style0 from "./Alerts.vue?vue&type=style&index=0&id=119aa806&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119aa806",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Home/vintikzzzz/Projects/webtor/webtor-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('119aa806')) {
      api.createRecord('119aa806', component.options)
    } else {
      api.reload('119aa806', component.options)
    }
    module.hot.accept("./Alerts.vue?vue&type=template&id=119aa806&scoped=true&", function () {
      api.rerender('119aa806', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/src/components/App/Alerts.vue"
export default component.exports