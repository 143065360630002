var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feature" }, [
    _c("h3", {
      domProps: { innerHTML: _vm._s(_vm.$tm("intro." + _vm.name + ".header")) }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "body",
      domProps: { innerHTML: _vm._s(_vm.text) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }