import { render, staticRenderFns } from "./Drop.vue?vue&type=template&id=03f71717&"
import script from "./Drop.vue?vue&type=script&lang=js&"
export * from "./Drop.vue?vue&type=script&lang=js&"
import style0 from "./Drop.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Home/vintikzzzz/Projects/webtor/webtor-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03f71717')) {
      api.createRecord('03f71717', component.options)
    } else {
      api.reload('03f71717', component.options)
    }
    module.hot.accept("./Drop.vue?vue&type=template&id=03f71717&", function () {
      api.rerender('03f71717', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/src/components/App/Send/Drop.vue"
export default component.exports