var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h1", { on: { click: _vm.clear } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("span", {
      staticClass: "name",
      domProps: { innerHTML: _vm._s(_vm.$tm("common.name")) }
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-back" }, [
      _c("span", { staticClass: "icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }