var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    { staticClass: "lang", attrs: { right: "", text: _vm.name } },
    _vm._l(_vm.translations, function(item, index) {
      return _c("item", { key: index, attrs: { item: index, index: index } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }