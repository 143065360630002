var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.appClass,
      attrs: { id: "app", "data-iframe-height": "", "data-iframe-width": "" }
    },
    [
      !_vm.isEmbedded ? _c("progress-bar") : _vm._e(),
      _vm._v(" "),
      _c("alerts"),
      _vm._v(" "),
      _c(
        "div",
        { ref: "content", staticClass: "content" },
        [
          _c(
            "keep-alive",
            [
              _vm.ready
                ? _c("router-view", { staticClass: "router-view" })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isEmbedded
        ? _c(
            "div",
            { ref: "footer", staticClass: "footer text-muted" },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "justify-content-md-center text-center" },
                    [
                      _c("b-col", { attrs: { lg: "10" } }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$tm("common.offer"))
                          }
                        }),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "link-block" }, [
                          _c(
                            "ul",
                            {
                              staticClass: "list-inline d-inline footer-links"
                            },
                            [
                              _c(
                                "li",
                                { staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "b-link",
                                    { attrs: { to: "torrent-to-ddl" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("nav.torrent to ddl"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "b-link",
                                    { attrs: { to: "magnet-to-torrent" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("nav.magnet to torrent"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "link-block" }, [
                          _c("span", { staticClass: "copyright" }, [
                            _vm._v(
                              "Copyright © " +
                                _vm._s(new Date().getFullYear()) +
                                " Webtor.io"
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass: "list-inline d-inline footer-links"
                            },
                            [
                              _c(
                                "li",
                                { staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.blogUrl
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("nav.blog")))]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href:
                                          "https://github.com/webtor-io/player-sdk-js"
                                      }
                                    },
                                    [_vm._v("SDK")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href:
                                          "https://rapidapi.com/paveltatarsky-Dx4aX7s_XBt/api/webtor/"
                                      }
                                    },
                                    [_vm._v("API")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: "https://github.com/webtor-io/"
                                      }
                                    },
                                    [_vm._v("GitHub")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                { staticClass: "list-inline-item" },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: "https://www.reddit.com/r/webtor/"
                                      }
                                    },
                                    [_vm._v("Reddit")]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.selfHosted
                                ? _c(
                                    "li",
                                    { staticClass: "list-inline-item" },
                                    [
                                      _c("b-link", { attrs: { to: "dmca" } }, [
                                        _vm._v(_vm._s(_vm.$t("nav.dmca")))
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.selfHosted
                                ? _c(
                                    "li",
                                    { staticClass: "list-inline-item" },
                                    [
                                      _c(
                                        "b-link",
                                        { attrs: { to: "support" } },
                                        [_vm._v(_vm._s(_vm.$t("nav.support")))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }