var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    { staticClass: "send-with-magnet large inverse" },
    [
      _c("icon", { staticClass: "magnet", attrs: { name: "magnet" } }),
      _vm._v(" "),
      _c("magnet", { staticClass: "magnet-input", attrs: { size: "lg" } }),
      _vm._v(" "),
      _c(
        "b-input-group-append",
        [_c("send", { attrs: { "drop-right": "", "no-magnet": "" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }