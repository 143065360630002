var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "download",
      attrs: {
        title: _vm.file,
        "sub-title": _vm.$t("common.size") + ": " + _vm.size
      }
    },
    [
      _c("icon", {
        staticClass: "type-icon",
        attrs: { name: _vm.typeIconName, scale: "3" }
      }),
      _vm._v(" "),
      _c(
        "b-input-group",
        {
          staticClass: "copy",
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function() {
                return [
                  _c(
                    "b-dropdown",
                    { attrs: { text: _vm.copyTitle, variant: "primary" } },
                    [
                      _c("b-dropdown-item", { on: { click: _vm.selectUrl } }, [
                        _vm._v("url")
                      ]),
                      _vm._v(" "),
                      _c("b-dropdown-item", { on: { click: _vm.selectWget } }, [
                        _vm._v("wget")
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("b-form-input", { attrs: { value: _vm.copyText, readonly: "" } }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-button",
                {
                  staticClass: "copy-btn",
                  attrs: { variant: "primary" },
                  on: { click: _vm.copy }
                },
                [_c("icon", { attrs: { name: "copy" } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showDownload
        ? _c(
            "b-button",
            {
              staticClass: "download-btn",
              attrs: { variant: "primary", size: "lg" },
              on: { click: _vm.proceedDownload }
            },
            [_vm._v(_vm._s(_vm.$t("common.direct download")))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showProgress
        ? _c(
            "b-button",
            {
              staticClass: "download-btn progress-btn",
              attrs: { variant: "primary", size: "lg" }
            },
            [
              _c("stat", {
                attrs: { path: _vm.downloadPath, download: "true" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "download-torrent-btn",
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.onTorrentDownload($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("common.torrent download")))]
      ),
      _vm._v(" "),
      _c("b-alert", {
        attrs: { variant: "warning", show: _vm.isZip },
        domProps: { innerHTML: _vm._s(_vm.$tm("errors.win zip")) }
      }),
      _vm._v(" "),
      _c("adsense-ad", {
        attrs: { "ad-name": "download", "ad-format": "auto" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }