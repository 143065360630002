var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    [
      _vm._t("pre", [
        _c("b-nav-item", { staticClass: "logo", attrs: { to: "/" } }, [
          _vm._v(_vm._s(_vm.$tm("common.domain")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "b-navbar-nav",
        { staticClass: "ml-auto" },
        [
          _c("donate"),
          _vm._v(" "),
          _c("lang", { staticClass: "last" }),
          _vm._v(" "),
          _vm._t("post")
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }