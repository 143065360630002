var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canShow
    ? _c(
        "div",
        { class: _vm.className },
        [
          _c(
            "b-list-group-item",
            {
              class: _vm.itemClass,
              attrs: { href: _vm.href, active: _vm.isActive, title: _vm.title },
              on: {
                click: _vm.onClick,
                mouseover: _vm.onMouseOver,
                mouseleave: _vm.onMouseLeave
              }
            },
            [
              _vm.showTitle
                ? _c(
                    "span",
                    { staticClass: "title" },
                    [
                      _vm.watched
                        ? _c("icon", {
                            staticClass: "state",
                            attrs: { name: "check", scale: "0.7" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.typeIconName
                        ? _c("icon", {
                            staticClass: "type-icon",
                            attrs: { name: _vm.typeIconName, scale: "1.5" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showSize
                        ? _c("div", [
                            _c("span", { staticClass: "file-name" }, [
                              _vm._v(_vm._s(_vm.title))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "size" }, [
                              _vm._v(_vm._s(_vm.size))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showSize
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "file-name file-name-without-size"
                              },
                              [_vm._v(_vm._s(_vm.title))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showStat
                ? _c("stat", { attrs: { path: _vm.item.path } })
                : _vm._e(),
              _vm._v(" "),
              _vm.showDownload && (!_vm.isVideo || !_vm.isActive)
                ? _c(
                    "span",
                    {
                      staticClass: "download",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.onDownload($event)
                        }
                      }
                    },
                    [
                      _vm.hovered && !_vm.isSupported && !_vm.isDir
                        ? _c("icon", {
                            staticClass: "status-icon",
                            attrs: { name: "download", scale: "1.5" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hovered && _vm.isSupported && !_vm.isDir
                        ? _c("icon", {
                            staticClass: "download-icon",
                            attrs: { name: "download", scale: "1.5" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showStatus
                ? _c(
                    "span",
                    { staticClass: "status" },
                    [
                      _vm.hovered &&
                      _vm.isSupported &&
                      !_vm.isActive &&
                      !_vm.isImage &&
                      !_vm.isDir
                        ? _c("icon", {
                            staticClass: "status-icon",
                            attrs: { name: "play", scale: "1.5" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hovered && _vm.isImage && !_vm.isActive
                        ? _c("icon", {
                            staticClass: "status-icon",
                            attrs: { name: "eye", scale: "1.5" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isImage && _vm.isActive && !_vm.inProgress
                        ? _c("icon", {
                            staticClass: "status-icon",
                            attrs: { name: "eye", scale: "1.5" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isPlaying && (!_vm.isVideo || !_vm.isActive)
                        ? _c("icon", {
                            staticClass: "status-icon blink",
                            attrs: { name: "play", scale: "1.5" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isPaused &&
                      !_vm.isImage &&
                      (!_vm.isVideo || !_vm.isActive)
                        ? _c("icon", {
                            staticClass: "status-icon",
                            attrs: { name: "pause", scale: "1.5" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.inProgress && (!_vm.isVideo || !_vm.isActive)
                        ? _c("icon", {
                            staticClass: "status-icon",
                            attrs: {
                              name: "circle-notch",
                              scale: "1.5",
                              spin: ""
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasError
                        ? _c("icon", {
                            staticClass: "status-icon",
                            attrs: { name: "ban", scale: "1.5" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.isActive ? _c("player", { staticClass: "player" }) : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }