import { render, staticRenderFns } from "./Donate.vue?vue&type=template&id=2e7d6aef&scoped=true&"
import script from "./Donate.vue?vue&type=script&lang=js&"
export * from "./Donate.vue?vue&type=script&lang=js&"
import style0 from "./Donate.vue?vue&type=style&index=0&id=2e7d6aef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e7d6aef",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Home/vintikzzzz/Projects/webtor/webtor-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e7d6aef')) {
      api.createRecord('2e7d6aef', component.options)
    } else {
      api.reload('2e7d6aef', component.options)
    }
    module.hot.accept("./Donate.vue?vue&type=template&id=2e7d6aef&scoped=true&", function () {
      api.rerender('2e7d6aef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/src/components/App/NavBar/Donate.vue"
export default component.exports