var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-alert", {
    attrs: { variant: "danger", show: _vm.message !== false, dismissible: "" },
    domProps: { innerHTML: _vm._s(_vm.message ? _vm.message : "") },
    on: { dismissed: _vm.clearDeliveryFailure }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }