var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.torrent
    ? _c(
        "div",
        { staticClass: "show" },
        [
          !_vm.isEmbedded
            ? _c("nav-bar", {
                staticClass: "nav",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "post",
                      fn: function() {
                        return [
                          _c(
                            "b-nav-item-dropdown",
                            {
                              staticClass: "hamburger",
                              attrs: { right: "", text: "☰" }
                            },
                            [
                              _c(
                                "template",
                                { slot: "button-content" },
                                [
                                  _c("icon", {
                                    attrs: { name: "bars", scale: "1.6" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.share-link-modal",
                                      modifiers: { "share-link-modal": true }
                                    }
                                  ],
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$tm("common.share link")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.magnet-uri-modal",
                                      modifiers: { "magnet-uri-modal": true }
                                    }
                                  ],
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$tm("common.magnet uri")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.$store.dispatch("zip")
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$tm("common.download")))]
                              )
                            ],
                            2
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3285289644
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              !_vm.isDownloadMode
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { staticClass: "justify-content-md-center" },
                        [
                          _c(
                            "b-col",
                            { attrs: { lg: "10" } },
                            [
                              _c(
                                "b-card",
                                { attrs: { "no-body": "" } },
                                [
                                  _vm.showHeader
                                    ? _c(
                                        "b-card-body",
                                        {
                                          staticClass: "header",
                                          attrs: {
                                            title: _vm.title,
                                            "sub-title": _vm.pwd
                                          }
                                        },
                                        [
                                          _c(
                                            "b-button-group",
                                            { staticClass: "header-buttons" },
                                            [
                                              _c("share-link", {
                                                staticClass: "share-link"
                                              }),
                                              _vm._v(" "),
                                              _c("magnet-uri", {
                                                staticClass: "magnet-uri"
                                              }),
                                              _vm._v(" "),
                                              _vm.showDownload
                                                ? _c("download-zip", {
                                                    staticClass: "zip"
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("list", { attrs: { items: _vm.items } })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.isEmbedded
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-button-toolbar",
                                        {
                                          staticClass:
                                            "send dropup justify-content-between"
                                        },
                                        [
                                          _c("send"),
                                          _vm._v(" "),
                                          _c(
                                            "b-button-group",
                                            [_c("sponsor")],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.hasLogin
                                        ? _c("login", { staticClass: "login" })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.displayAdsense
                        ? _c(
                            "b-row",
                            {
                              staticClass: "justify-content-md-center adsense"
                            },
                            [
                              _c(
                                "b-col",
                                { attrs: { lg: "10" } },
                                [
                                  _c("adsense-ad", {
                                    attrs: {
                                      "ad-name": "showBottom",
                                      "ad-format": "auto"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isDownloadMode
            ? _c(
                "b-modal",
                {
                  ref: "download-modal",
                  attrs: {
                    visible: _vm.showDownloadModal,
                    id: "download-modal",
                    "hide-footer": "",
                    centered: "",
                    "no-fade": ""
                  },
                  on: { hide: _vm.onDownloadHide }
                },
                [_c("download")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isDownloadMode && _vm.showDownloadModal
            ? _c("download")
            : _vm._e(),
          _vm._v(" "),
          _vm.displayAdsense && _vm.windowWidth > 1425
            ? _c("adsense-ad", {
                staticClass: "sticky-ad",
                attrs: { "ad-name": "showLeftSticky", "ad-format": "auto" }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }