import { render, staticRenderFns } from "./Lang.vue?vue&type=template&id=533e14ee&"
import script from "./Lang.vue?vue&type=script&lang=js&"
export * from "./Lang.vue?vue&type=script&lang=js&"
import style0 from "./Lang.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Home/vintikzzzz/Projects/webtor/webtor-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('533e14ee')) {
      api.createRecord('533e14ee', component.options)
    } else {
      api.reload('533e14ee', component.options)
    }
    module.hot.accept("./Lang.vue?vue&type=template&id=533e14ee&", function () {
      api.rerender('533e14ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/src/components/App/NavBar/Lang.vue"
export default component.exports